.main {
  padding-block-start: calc(env(safe-area-inset-top) + 1rem);
  padding-block-end: calc(env(safe-area-inset-bottom) + 1rem);
  padding-inline-start: calc(env(safe-area-inset-left) + 1rem);
  padding-inline-end: calc(env(safe-area-inset-right) + 1rem);
  position: relative;
  inline-size: min(calc(560 / 16 * 1rem), 100vw);
  block-size: 100%;
  margin: auto;
}
