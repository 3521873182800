.timer {
  display: flex;
  align-items: center;
  gap: calc(var(--grid-size) / 2);
}

.text {
  line-height: 1.25;
  font-size: calc(22 / 16 * 1rem);
  font-weight: normal;
}
