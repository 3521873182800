.message {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  inline-size: 100%;
}

.message[hidden] {
  display: none;
}

.text {
  flex: 1;
  font-size: 1rem;
  opacity: 0.95;
}

.arrow {
  transform: rotate(243deg) scaleX(-1);
}
