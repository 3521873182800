.heading {
  font-size: calc(18 / 16 * 1rem);
  font-weight: bold;
  margin: 0 0 calc(var(--grid-size) * 2.5);
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: calc(var(--grid-size) * 2);
}

.icon {
  display: block;
}
