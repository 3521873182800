.gameEnd {
  background-color: var(--fg-color);
  color: #000;
  text-align: center;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100vw;
  z-index: 1;
}

.heading {
  margin: 0;
  font-size: calc(30 / 16 * 1rem);
  line-height: 1.3;
}

.shareHeading {
  font-size: calc(18 / 16 * 1rem);
  font-weight: bold;
  margin: 0 0 calc(var(--grid-size) * 2.5);
}

.lineBreak {
  display: block;
}

.icon {
  block-size: calc(var(--grid-size) * 8);
  margin-block-start: calc((80 / 16 * 1rem) + env(safe-area-inset-top));
}

.share {
  margin-block-start: calc(var(--grid-size) * 6);
  margin-block-end: calc(var(--grid-size) * 11);
}

.actions {
  margin-block-start: calc(var(--grid-size) * 11);
  display: grid;
  place-content: center;
  gap: var(--grid-size);
}
