.form {
  margin-block-start: min(calc(220 / 16 * 1rem), 48%);
}

.form input {
  flex: 1;
  padding: 0;
  all: inherit;
}

.form::after {
  content: '';
  display: block;
  block-size: 2px;
  inline-size: 100%;
  margin-block-start: var(--grid-size);
  background-color: var(--fg-color);
  border-radius: 2px;
}

.content {
  display: flex;
  align-items: center;
  inline-size: 100%;
  text-transform: uppercase;
  font-size: calc(30 / 16 * 1rem);
  line-height: 1.0666;
  font-weight: 800;
  user-select: none;
}
