.button {
  --overlay-opacity: 0;

  --_bg-color: var(--bg-color-alert);
  --_fg-color: var(--fg-color);
  --_border-color: transparent;

  font-size: calc(18 / 16 * 1rem);
  line-height: 1.33;
  color: var(--_fg-color);
  font-family: inherit;
  font-weight: bold;
  padding: 0 calc(var(--grid-size) * 2);
  block-size: calc(var(--grid-size) * 6);
  background: linear-gradient(rgba(0, 0, 0, var(--overlay-opacity)) 0 100%);
  background-color: var(--_bg-color);
  cursor: pointer;
  border: 3px solid var(--_border-color);
  display: flex;
  gap: var(--grid-size);
  align-items: center;
  min-inline-size: calc(var(--grid-size) * 22);
}

.button:active {
  --overlay-opacity: 0.15;
}

.outlined {
  --_bg-color: var(--fg-color);
  --_fg-color: var(--bg-color-alert);
  --_border-color: var(--bg-color-alert);
}

.transparent {
  --_bg-color: transparent;
  min-inline-size: unset;
  border: none;
}

.icon > svg {
  display: block;
}
